<template>
        <section class="noticias">
            <div class="container">
                <div class="section-header">
                    <h2 class="section-title">Noticias</h2>
                    <dropdown class="my-dropdown-toggle"
                        :options="dropdownOptions" 
                        :selected="dropdownSelected()" 
                        v-on:updateOption="OnSelect" 
                        :placeholder="'Todas'"
                        :closeOnOutsideClick="true">
                    </dropdown>
                </div>

                <div class="section-content" v-if="isLoading">
                    <div class="col s6 m4 l3" v-for="(n,index) in 12" :key=index>
                        <NewsItem />
                    </div> 
                </div>
                <div class="section-content" v-else-if="news.data && news.data.length > 0">
                    <div class="col s6 m4 l3" v-for="(newsItem, index) in news.data" :key=index>
                        <NewsItem :item="newsItem" />
                    </div>
                    <Spinner v-if="isLoadingMore"/>
                    <div class="bottom-container" v-if="loadMoreStatus && !isLoadingMore"><a @click="loadMore()" class="load-more">Cargar Más</a></div>
                </div>
                <div class="section-content" v-else>
                    <NotFound/>
                </div>
            </div>
        </section>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import NewsItem from '@/components/NewsItem.vue'
import NavBar from '@/components/NavBar.vue'
import dropdown from 'vue-dropdowns'
import router from '../router'
import NotFound from '@/components/NotFound.vue'

export default {
    name: 'Noticias',
    components: {
        NavBar,
        NewsItem,
        Spinner,
        dropdown,
        NotFound
    },

    data(){
        return{
            news: [],
            isLoading: true,
            page: 1,
            loadMoreStatus: false,
            isLoadingMore: false,
            dropdownOptions: [{ name: 'Todas'}],
            currentPath: null,
            newPath: null
        }
    },
    
    mounted(){
        (async () => {
            const category = this.$route.params.category

            this.setHeader('page', {
                titulo: 'Noticias'
            })

            this.isLoading = true
            this.news.data = []
            this.news.meta = []

            this.currentPath = window.location.pathname+window.location.search
            
            // get categories
            let response = await this.getItems('categorias')
            if( response.data && response.data.length > 0){
                response.data.forEach(item => {
                    this.dropdownOptions.push({name: item.nombre})
                });
            }

            this.loadItems()
        })()
    },
    methods: {
        loadItems: async function(){
            let previousNews = this.news.data

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            let filter = 'page='+this.page+'&filter[estado][eq]=publicado&meta=total_count,result_count,filter_count';

            if(params.filter && params.filter != 'Todas'){
                filter+= '&filter[categoria.nombre][eq]='+params.filter
            }
            
            this.news = await this.getItems('noticias', 12, filter)

            this.news.data = previousNews.concat(this.news.data)

            if( (this.news.meta.filter_count > this.news.data.length && params.filter) || (this.news.meta.filter_count > this.news.data.length  && typeof(params.filter) == 'undefined') ){
                this.page++
                this.loadMoreStatus = true
            }else{
                this.loadMoreStatus = false
            }

            this.isLoading = false
        },
        loadMore: async function(){
            this.isLoadingMore = true
            await this.loadItems()
            this.isLoadingMore = false
        },
        OnSelect: function(payload) {
            this.newPath = '/noticias?filter='+encodeURIComponent(payload.name)

            if( this.newPath != this.currentPath){
                this.page= 1
                this.currentPath = this.newPath
                this.isLoading = true
                router.push('/noticias?filter='+payload.name)
            }
        },
        dropdownSelected: function(){

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            if(params.filter){
                return { name: params.filter }
            }else{
                return { name: 'Todas' }
            }
        }
    },
    watch:{
        $route (to, from){
            this.news.data = []
            this.loadItems()
        }
    }
}
</script>